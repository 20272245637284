import * as React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} >
        <h5>{props.TipTitle}</h5>
        <p>{props.Tiptext}</p>
    </Tooltip>
);

export const CustomTooltip = ({ children, TipTitle, Tiptext, placement }) => {
    return (
        <OverlayTrigger
            placement={placement}
            delay={{ show: 300, hide: 400 }}
            overlay={renderTooltip({ TipTitle, Tiptext })}
        >
            {children}
        </OverlayTrigger>
    )
}