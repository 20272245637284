import * as React from "react"
import { FooterComponent } from "../../Components/FooterComponent"
import HeaderComponent from "../../Components/HeaderComponent"
import Layout from "../../Components/Layout"
import Seo from "../../Components/Seo"
import { TRIP_PRICING_PAGE } from "../../constant/routes"
import HomePage from '../../modules/TripSuite'

const IndexPage = () => {
    return (
        <>
            <Seo title="Trip Suite | Webbermill"/>
            <Layout className="TripHome">
                <title>Trip Home Page</title>
                <HeaderComponent hasPrice priceUrl={TRIP_PRICING_PAGE} loginUrl="http://transport.webbermill.com/"/>
                <HomePage />
                <FooterComponent />
            </Layout>
        </>
    )
}

export default IndexPage
