
import * as React from "react"
import { CustomTabs } from "../../Components/CustomTabs"
import CustomCarousel from "../../Components/CustomCarousel"
import { FeaturesCard } from "../../Components/CustomCard"
import { CustomAccordion, FaqAccordion } from "../../Components/CustomAccordion"
import { AccordionText, FAQText } from "../../constant/constants"
import { CustomTooltip } from "../../Components/CustomTooltip"
import { CONTACT_PAGE } from "../../constant/routes"
import { CustomLinkBtn } from "../../Components/CustomButton"
import homeContent from '../../../content/homePageContent.json'
import CustomModal from "../../Components/CustomModal"
import GetStartedForm from "../Home/components/GetStartedForm"




const TripIndexPage = () => {
    const [show, setShow] = React.useState(false);
    const [modalShow, setModalShow] = React.useState(false);

    const handleShow = () => setShow(!show)
    return (
        <>
            <section className="TripHome__content ">
                <div className="TripHome__content-banner container">
                    <div className="TripHome__content-banner-1">
                        <div className="text">
                            <p>{homeContent.heroSection.headlines[0]}</p>
                            <h5>Simple and complete cloud-base <br /> transport management software</h5>

                            <div className="banner-btn">
                            <a onClick={() => setModalShow(true)} href="#">Get Started </a>
                                <CustomLinkBtn text="Contact Sales" url={CONTACT_PAGE} />
                            </div>
                        </div>
                    </div>
                    <div className="TripHome__content-banner-2">
                        <div className="banner-img">
                            {/* <MonitorFrame /> */}
                            {/* <div className="bannerGif">
                                <img src="../banner/banner.png" alt="Banner" width={600} />
                            </div> */}
                            <img className="banner" src="https://res.cloudinary.com/webbermill/image/upload/v1679606054/webbermill-website-assets/static/banner/banner_fivoum.png" alt="Banner" />
                        </div>
                        <div className="stores text-center">
                            <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606055/webbermill-website-assets/static/banner/playstore_vjhl83.png" alt="Banner" width={133} height={45} style={{ objectFit: "cover"}}/>
                            <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606056/webbermill-website-assets/static/banner/applestore_zp4fas.png" alt="Banner" width={133} height={45} style={{ marginLeft: 30 ,  objectFit: "cover"}} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="TripHome__tabs">
                <div className="TripHome__tabs-content container">
                    <div className="TripHome__tabs-content-text">
                        <h5>Give your team & business a 360° transformation</h5>
                        <h5 className="moblie_text">Transform how your team transport</h5>
                        <p>Unite your team & assets all at one place, transport smarter and reveal new insights to stay ahead</p>
                    </div>
                    <div className="TripHome__tabs-content-tabs">
                        <CustomTabs />
                    </div>
                </div>
            </section>
            {/*  */}
            <section className="TripHome__customer">
                <div className="TripHome__customer-text">
                    <h5>What our customers are saying</h5>
                </div>
                <div className="container">
                    {/* <CustomersInfoCard /> */}
                    <CustomCarousel />
                </div>
            </section>
            {/*  */}
            <section className="TripHome__features">
                <div className="TripHome__features-text">
                    <h5>All the productive features in one place</h5>
                    <p>Put your transportation business on steroids' by having all the moving parts of your business at one place and <br /> integrate with other apps easily and faster</p>
                </div>
                <div className="TripHome__features-card container">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <FeaturesCard feature="Trip Management" src="https://res.cloudinary.com/webbermill/image/upload/v1679606051/webbermill-website-assets/static/trip_icons/product1_k1kmfr.svg" alt="Banner" name="trip" description="Easily plan trips with all moving part in one place &  complete legal compliance." />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <FeaturesCard feature="Customer Center" src="https://res.cloudinary.com/webbermill/image/upload/v1679606052/webbermill-website-assets/static/trip_icons/product2_yarx3w.svg" alt="Banner" name="custom" description="Build an elaborate database of your customers."/>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <FeaturesCard feature="BI - Dashboard" src="https://res.cloudinary.com/webbermill/image/upload/v1679606052/webbermill-website-assets/static/trip_icons/product3_o3ca3p.svg" alt="Banner" name="order" description="All the important KPIs & other business metrics and data intelligently displayed."/>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <FeaturesCard feature="Way Bill & Invoice" src="https://res.cloudinary.com/webbermill/image/upload/v1679606051/webbermill-website-assets/static/trip_icons/product4_lw9slj.svg" alt="Banner" name="waybill" description="Intelligently merge trip numbers with clients waybills."/>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <FeaturesCard feature="Driver Management" src="https://res.cloudinary.com/webbermill/image/upload/v1679606051/webbermill-website-assets/static/trip_icons/product5_pz6dhl.svg" alt="Banner" name="driver" description="Keep tabs on all your drivers status and performance."/>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <FeaturesCard feature="Journey Comm Log" src="https://res.cloudinary.com/webbermill/image/upload/v1679606050/webbermill-website-assets/static/trip_icons/product6_pmssgp.svg" alt="Banner" name="data" description="Track the development on each trip, update & close when incidents are fully resolved."/>
                        </div>
                    </div>

                    <div className={`row ${show === true ? 'd-flex' : 'd-none'}`} >
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <FeaturesCard feature="Depot Loading" src="https://res.cloudinary.com/webbermill/image/upload/v1679606050/webbermill-website-assets/static/trip_icons/product7_oflsav.svg" alt="Banner" name="depot" description="Monitor the activities of your trucks at all loading points."/>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <FeaturesCard feature="Violations" src="https://res.cloudinary.com/webbermill/image/upload/v1679606049/webbermill-website-assets/static/trip_icons/product8_w6dcgi.svg" alt="Banner" name="violation" description="Manage employees violations intelligently by categorization & severity levels."/>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <FeaturesCard feature="Losses" src="https://res.cloudinary.com/webbermill/image/upload/v1679606049/webbermill-website-assets/static/trip_icons/product9_mevobr.svg" alt="Banner" name="losses" description="Simple but powerful tool to manage short falls in deliveries."/>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <FeaturesCard feature="Compilance Tracker" src="https://res.cloudinary.com/webbermill/image/upload/v1679606053/webbermill-website-assets/static/trip_icons/product10_ilan0t.svg" alt="Banner" name="tracker" description="Spend more time working and let us automate your legal compliance."/>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <FeaturesCard feature="Report" src="https://res.cloudinary.com/webbermill/image/upload/v1679606052/webbermill-website-assets/static/trip_icons/product12_w4rrky.svg" alt="Banner" name="report" description="Generate reports instantly and reveal interesting insights."/>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <FeaturesCard feature="Potential Incident" src="https://res.cloudinary.com/webbermill/image/upload/v1679606053/webbermill-website-assets/static/trip_icons/product11_w4tacb.svg" alt="Banner" name="potential" description="Build intelligent information on potential occurrences on roads and instantly share  with stakeholders"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-5 col-lg-5 mx-auto">
                            <div className="more-link" onClick={handleShow} aria-hidden="true">
                                <span className="linkBtn">{
                                    show === true ? (
                                        "Hide features"
                                    ) : (
                                        "See all features"
                                    )
                                }</span>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  */}
            <section className="TripHome__workflow">
                <div className="container TripHome__workflow-content">
                    <div className="TripHome__workflow-text">
                        <h5>Flexible and adapts to all <br /> transportation workflow</h5>
                        <p> Let us do the hardwork, analyze data and present your team <br />  with the information allowing them to do more. </p>
                        
	        
                        <div className="accordion_content">

                            <CustomAccordion AccordionText={AccordionText} />

                        </div>
                    </div>
                    <div className="TripHome__workflow-diagram">
                         <div className="TripHome__workflow-btn">
                            <button>Bulk oil transportation by road</button>
                        </div>
                        <div className="lines">
                                
                                <img className="lines-img" src="https://res.cloudinary.com/webbermill/image/upload/v1679606054/webbermill-website-assets/static/banner/lines3_oprjeu.gif" alt="Banner" width="100%" height={400} />
                            <div className="pic1" id="workflow_logo">
                                <CustomTooltip placement="right" TipTitle="New Order Received">
                                    <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606048/webbermill-website-assets/static/trip_icons/workflow5_neghat.svg" alt="Worflow icon" width={50} />

                                </CustomTooltip>
                            </div>
                            <div className="pic2" id="workflow_logo">
                                <CustomTooltip placement="right" TipTitle="Fleet Manager" Tiptext="Initiate Trips">
                                    <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606049/webbermill-website-assets/static/trip_icons/workflow2_fgbviu.svg" alt="Worflow icon" width={50} />

                                </CustomTooltip>
                            </div>
                            <div className="pic3" id="workflow_logo">
                                <CustomTooltip placement="bottom" TipTitle="Journey Manager" Tiptext="Plans & Schedules Trip">
                                    <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606049/webbermill-website-assets/static/trip_icons/workflow3_jn7k2g.svg" alt="Worflow icon" width={50} />

                                </CustomTooltip>
                            </div>
                           
                            <div className="pic5" id="workflow_logo">
                                <CustomTooltip placement="bottom" TipTitle="Depot Rep" Tiptext="Assist with Loading & Keep Records">
                                    <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606049/webbermill-website-assets/static/trip_icons/workflow4_ink9go.svg" alt="Worflow icon" width={50} />

                                </CustomTooltip>
                            </div>
                            <div className="pic6" id="workflow_logo">
                                <CustomTooltip placement="top" TipTitle="Destination" Tiptext="Sleeping or Delivery Point">
                                    <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606050/webbermill-website-assets/static/trip_icons/workflow1_roihok.svg" alt="Worflow icon" width={50} />

                                </CustomTooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  */}
            <section className="TripHome__faq">
                <div className="TripHome__faq-text">
                    <h5>Frequently asked questions</h5>


                    <div className="accordion_content-2 container">
                        <div className="row mt-5">
                            <div className="col-10 mx-auto">
                                <FaqAccordion AccordionText={FAQText} />
                            </div>
                        </div>


                    </div>
                </div>
            </section>
            {/*  */}
            <section className="TripHome__suscribe">
                <div className="TripHome__suscribe-text container">
                    <h5>Get started for free today</h5>
                    <p>30-day free trial | No payment required.</p>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6 mx-auto">
                            <div className="form_content text-white">
                                {/* <form>
                                    <div className="form-group d-flex">
                                        <input className="form-control" type="text" placeholder="Enter business email" />
                                        <button type="button" className="btn btn-primary">Get Started</button>
                                    </div>
                                </form> */}

                                <GetStartedForm />


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <CustomModal  show={modalShow} onHide={() => setModalShow(false)} className="getStarted">
            <GetStartedForm hasText/>
            </CustomModal>
        </>
    );
};

export default TripIndexPage;
