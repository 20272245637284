import * as React from 'react'
import { Link } from 'gatsby'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

export const CustomTabs = () => {
  return (
    <>
      <Tabs>
        <TabList>
          <Tab>Planning</Tab>
          <Tab>Execution</Tab>
          <Tab>Optimization</Tab>
        </TabList>

        <TabPanel>
          <div className="tabs-content">
            <div className="text">
              <h5>
                Super-charge your trip planning with all <br /> assets in one
                place real-time
              </h5>
              <p>
                Take your trip planning to next level With Webbermill
                transportation app. From daily truck availability to driver
                availability report working with trip management module you a
                minute away from initiating a trip. Webbermill transport app
                instantly allow your trip planning process to comply with
                statutory regulations, organisational and other stakeholders
                safety measures.
              </p>
              <Link to="">Learn More</Link>
            </div>
            <div className="img-holder">
              <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606057/webbermill-website-assets/static/banner/plan_nwlygo.png" alt="" width="100%" />
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="tabs-content">
            <div className="text">
              <h5>
                Track, document and communicate with <br /> carriers and manage
                expenses
              </h5>
              <p>
                Move away from manual documentation to full digitisation of your
                execution of your plan trip capturing waybills, invoices &
                signatures digitally. Track your vehicle, driver and goods all on
                our transport app, by easil integrating your already tracking
                provider into our system.
              </p>
              <Link to="">Learn More</Link>
            </div>
            <div className="">
              <img
                src="https://res.cloudinary.com/webbermill/image/upload/v1679606054/webbermill-website-assets/static/banner/execute_u7o4kw.png"
                alt=""
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="tabs-content">
            <div className="text">
              <h5>
                Reveal new insights and make inform <br /> changes that impact
                your business
              </h5>
              <p>
                Webbermill transportation app gives you the the ability to
                measure and track performance with reports, dashboards,
                analytics, and transportation intelligence. All these
                intelligent tools allow you the ability to scale your business
                by meeting and exceeding customer demands for fast, on-time
                delivery.
              </p>
              <Link to="">Learn More</Link>
            </div>
            <div className="">
              <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606055/webbermill-website-assets/static/banner/optimize_tosnvt.png" alt="" width="100%" />
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </>
  )
}
