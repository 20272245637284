import * as React from "react"
import { CustomersInfoCard } from "./CustomCard";
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"

const CustomCarousel = () => {
     const [currentSlide, setCurrentSlide] = React.useState(0)
  const [loaded, setLoaded] = React.useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })
    
    return (
        <>
          <div ref={sliderRef} className="keen-slider">
          <div className="keen-slider__slide number-slide1">
           <CustomersInfoCard />
          </div>
          {/* <div className="keen-slider__slide number-slide2">
             <CustomersInfoCard />
          </div>
          <div className="keen-slider__slide number-slide3">
             <CustomersInfoCard />
          </div> */}
          
          </div>
          {loaded && instanceRef.current && (
        <div className="dots">
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx)
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              ></button>
            )
          })}
        </div>
      )}
        </>
    )
}

export default CustomCarousel
