import * as React from "react";
import { Accordion } from "react-bootstrap"

export const CustomAccordion = ({ AccordionText }) => {
    return (
        <>
            <Accordion defaultActiveKey="0" flush>
                {
                    AccordionText.map((item) => (
                        <Accordion.Item eventKey={item.id} key={item.id}>
                            <Accordion.Header>{item.heading}</Accordion.Header>
                            <Accordion.Body>
                                {item.content}
                            </Accordion.Body>
                        </Accordion.Item>
                    ))
                }
                <hr />
            </Accordion>
        </>
    )
}

export const FaqAccordion = ({ AccordionText }) => {
    return (
        <>
            <Accordion defaultActiveKey="0" flush>
                {
                    AccordionText.map((item) => (
                        <Accordion.Item eventKey={item.id} key={item.id}>
                            <Accordion.Header>{item.heading}</Accordion.Header>
                            <Accordion.Body>
                                {item.content}
                            </Accordion.Body>
                        </Accordion.Item>
                    ))
                }
                {/* <hr /> */}
            </Accordion>
        </>
    )
}


